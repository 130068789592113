import React, { useRef, useEffect, FC, ReactNode } from 'react';
import PropTypes from 'prop-types';

import Alert, { AlertBody, AlertMessage } from 'orm-react/components/Alert';

const BlockSuccess: FC<{
  title: string;
  children: ReactNode;
}> = ({ title, children }) => {
  const block = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { current } = block;

    setTimeout(() => {
      if (current && current.parentNode) {
        current.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    }, 0);
  }, []);

  return (
    <div ref={block}>
      <Alert type="success" wide="md" className="text-center">
        <AlertBody>
          <AlertMessage title={title}>{children}</AlertMessage>
        </AlertBody>
      </Alert>
    </div>
  );
};

BlockSuccess.propTypes = {
  title: PropTypes.string.isRequired
};

export default BlockSuccess;
