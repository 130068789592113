import React, { useRef, useEffect, FC, ReactNode, useState } from 'react';

import useIntersectionObserver from 'orm-react/hooks/intersection-observer';

const easeOutQuad = (t: number, b: number, c: number, d: number) =>
  -c * (t /= d) * (t - 2) + b; // eslint-disable-line
const easeInQuad = (t: number, b: number, c: number, d: number) =>
  c * (t /= d) * t + b; // eslint-disable-line
const easeOutCirc = (t: number, b: number, c: number, d: number) =>
  c * Math.sqrt(1 - (t = t / d - 1) * t) + b; // eslint-disable-line
const easeInCirc = (t: number, b: number, c: number, d: number) =>
  -c * (Math.sqrt(1 - (t /= d) * t) - 1) + b; // eslint-disable-line

const ElementorCounter: FC<{
  className: string;
  children: ReactNode;
  'data-duration': string;
  'data-to-value': string;
  'data-delimiter': string;
  'data-from-value': string;
}> = ({
  className,
  'data-duration': duration,
  'data-to-value': toValue,
  'data-from-value': from,
  'data-delimiter': delimiter,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const intersection = useIntersectionObserver(ref, {});
  const isVisible = !!intersection?.isIntersecting;
  const [currentCount, setCurrentCount] = useState<number>(0);
  let timeout: ReturnType<typeof setTimeout>;
  const to = parseInt(toValue, 10);
  const dur = parseInt(duration, 10);

  const countCounter = ({
    rest,
    count,
    step,
    steps,
    total = 0,
    last = 0
  }: {
    rest: number;
    count: number;
    step: number;
    steps: number;
    total?: number;
    last?: number;
  }) => {
    if (rest > 0) {
      const current = steps - rest;
      const calc = Math.round(easeInCirc(current, 0, dur, steps));
      const time = Math.abs(last - calc);

      setCurrentCount(Math.floor(count));

      timeout = setTimeout(countCounter, time, {
        rest: rest - 1,
        step,
        steps,
        count: count + step,
        total: total + time,
        last: calc
      });
    } else {
      setCurrentCount(to);
      if (timeout) clearTimeout(timeout);
    }
  };

  useEffect(() => {
    const steps = Math.round(dur / 16);
    const start = parseInt(from, 10);

    setCurrentCount(start);

    if (isVisible) {
      // @ts-ignore
      timeout = setTimeout(countCounter, 0, {
        rest: steps,
        steps,
        to,
        dur,
        step: (to - start) / steps,
        count: start
      });

      return () => {
        if (timeout) clearTimeout(timeout);
      };
    }
  }, [isVisible]);

  return (
    <span ref={ref} className={className} {...props}>
      {currentCount}
    </span>
  );
};

export default ElementorCounter;
