import './styles.module.scss';

import { useField, useForm } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  FieldButton,
  FieldError,
  FieldGroup,
  FieldTime
} from 'orm-react/components/Field';

import {
  TypeDeadlineTime,
  TypeDeliveryTimes
} from 'orm-react/@types/graphql-types';
import { Button } from 'orm-react/components/Button';
import { FormFieldset } from 'orm-react/components/Form';

import FormGroupDeadlineTime from '../DeadlineTime';
import { Fragment } from 'react';

const DefaultDeadlineTime: TypeDeadlineTime = {
  monday: [0, 0, 0],
  tuesday: [0, 0, 0],
  wednesday: [0, 0, 0],
  thursday: [0, 0, 0],
  friday: [0, 0, 0],
  saturday: [0, 0, 0],
  sunday: [0, 0, 0]
};

const FormGroupTimeList = ({
  name,
  label
}: {
  name: string;
  label: string;
}) => {
  const form = useForm();
  const { input } = useField(name);
  const times: TypeDeliveryTimes = input.value;

  const removeTime = (remove: number) => () => {
    const tmp = [...times];
    tmp.splice(remove, 1);
    if (tmp.length === 0) {
      tmp.push({
        deliveryTime: 0,
        deadlineTime: DefaultDeadlineTime
      });
    }
    form.change(name, tmp);
    form.mutators.changed(name);
  };

  const addTime = () => {
    const tmp = [...times];
    tmp.push({
      deliveryTime: 0,
      deadlineTime: DefaultDeadlineTime
    });
    form.change(name, tmp);
    form.mutators.changed(name);
  };

  return (
    <div className="form__time-list">
      <FieldError name={name} />
      {times.map((time, index) => (
        <Fragment key={`${name}_${name}_${String(index)}`}>
          <FormFieldset
            label={
              <div>
                <span>{label}</span>
              </div>
            }
            grouped
          >
            <FieldGroup>
              <FieldTime
                name={`${name}[${index}].deliveryTime`}
                minuteStep={5}
                required
              />
              {index > 0 && (
                <Button
                  variant="text"
                  type="button"
                  onClick={removeTime(index)}
                >
                  <i>
                    <FontAwesomeIcon icon={['fas', 'times']} />
                  </i>
                  <span>{__('btn.delete')}</span>
                </Button>
              )}
            </FieldGroup>
          </FormFieldset>
          <FormFieldset
            label={
              <div>
                <span>{__('label.deadline.time')}</span>
              </div>
            }
          >
            <FormGroupDeadlineTime name={`${name}[${index}].deadlineTime`} />
          </FormFieldset>
        </Fragment>
      ))}
      <FieldButton>
        <Button
          variant="text"
          className="single"
          type="button"
          onClick={addTime}
        >
          <i>
            <FontAwesomeIcon icon={['fas', 'plus']} />
          </i>
          <span>{__('btn.add.more.delivery.time')}</span>
        </Button>
      </FieldButton>
    </div>
  );
};

export default FormGroupTimeList;
