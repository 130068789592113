import {
  TypeDeadlineList,
  TypeDeadlineTimeKeys
} from 'orm-react/@types/graphql-types';

import styles from './styles.module.scss';

import { Fragment } from 'react';
import { useField } from 'react-final-form';

import {
  FieldGroup,
  FieldError,
  FieldSelect
} from 'orm-react/components/Field';
import { FieldSelectOptions } from 'orm-react/components/Field/Select';

import { WEEKDAYS } from 'orm-react/i18n';

import { getUTCDate } from 'orm-lib/helper/date';
import { formatDate } from 'orm-react/helper/date';
import { getDeadline, weekDayIndex, weekDays } from 'orm-lib/helper/deadline';
import { intToHour, intToMinute } from 'orm-react/helper/string';

const days = Array.from(Array(8).keys()).map((value) => ({
  value,
  name: String(value)
}));
const hours = Array.from(Array(24).keys()).map((value) => ({
  value,
  name: String(value)
}));
const minutes = Array.from(Array(60 / 5).keys()).map((value) => ({
  value: value * 5,
  name: String(value * 5)
}));

const DeadlineTimeView = ({
  name,
  day
}: {
  name: string;
  day: TypeDeadlineTimeKeys;
}) => {
  const { input: deliveryTime } = useField(
    name.replace('deadlineTime', 'deliveryTime')
  );
  const { input: deadlineTime } = useField(name);
  const deadlineTimes: TypeDeadlineList = deadlineTime.value[day].map(Number);

  const hours = intToHour(deliveryTime.value);
  const minutes = intToMinute(deliveryTime.value);

  const today = getUTCDate(new Date());
  const currentDay = today.getDay();
  // set week date
  today.setDate(today.getDate() + (weekDayIndex[day] - currentDay));
  today.setUTCMinutes(minutes);
  today.setUTCHours(hours);

  const deadlineDateTime = getDeadline({
    day: today,
    deadlineTime: deadlineTimes,
    time: Number(deliveryTime.value)
  });

  return (
    <div className={styles.form__deadline__example}>
      <div>
        <span>{__('label.delivery.time')}</span>
        {formatDate(today, 'weekday')} - {formatDate(today, 'time')}
      </div>
      <div>
        <span>{__('label.deadline.time')}</span>
        {`${formatDate(deadlineDateTime, 'weekday')} - ${formatDate(
          deadlineDateTime,
          'time'
        )}`}
      </div>
    </div>
  );
};

const FormGroupDeadlineTime = ({ name }: { name: string }) => (
  <div className={styles.form__deadline}>
    <FieldError name={name} />
    {weekDays.map((key) => (
      <Fragment key={key}>
        <FieldGroup key={key}>
          <label>{WEEKDAYS[key]}</label>
          <FieldSelect name={`${name}.${key}[0]`} label={__('label.minutes')}>
            <FieldSelectOptions options={minutes} />
          </FieldSelect>
          <FieldSelect name={`${name}.${key}[1]`} label={__('label.hours')}>
            <FieldSelectOptions options={hours} />
          </FieldSelect>
          <FieldSelect name={`${name}.${key}[2]`} label={__('label.days')}>
            <FieldSelectOptions options={days} />
          </FieldSelect>
        </FieldGroup>
        <DeadlineTimeView name={name} day={key} />
      </Fragment>
    ))}
  </div>
);

export default FormGroupDeadlineTime;
