import React, { useRef, useEffect, FC, ReactNode } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const ElementorToggle: FC<{
  children: ReactNode;
  className: string;
}> = ({ className, children, ...props }) => {
  const toggle = useRef<HTMLDivElement>(null);
  const lastIndex = useRef(-1);
  const list: Array<Element | HTMLElement> = [];
  const listener: EventListener[] = [];

  const toggleMenu = (index: number) => () => {
    const { current } = lastIndex;

    if (
      className === 'elementor-accordion' &&
      current >= 0 &&
      current !== index
    ) {
      list[current].classList.remove('elementor-active');
    }

    list.forEach((node, i) => {
      if (index === i && !node.classList.contains('elementor-active')) {
        lastIndex.current = index;
        node.classList.add('elementor-active');
      } else if (index === i && node.classList.contains('elementor-active')) {
        node.classList.remove('elementor-active');
      }
    });
  };

  useEffect(() => {
    const { current } = toggle;

    if (!current) return;

    current
      .querySelectorAll('.elementor-tab-title')
      .forEach((node) => list.push(node));
    current.querySelectorAll('a').forEach((node) => {
      node.addEventListener('click', (e) => {
        e.preventDefault();
        return false;
      });
    });

    list.forEach((node, i) => {
      listener[i] = toggleMenu(i);
      node.addEventListener('click', listener[i]);
    });

    return () => {
      list.forEach((node, i) => node.removeEventListener('click', listener[i]));
    };
  }, []);

  return (
    <div className={className} {...props} ref={toggle}>
      {children}
    </div>
  );
};

ElementorToggle.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default ElementorToggle;
