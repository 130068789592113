import React, { FC, useRef, useEffect, ReactNode } from 'react';
import PropTypes from 'prop-types';

const ElementorScript: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const instance = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // @ts-ignore
    const [src] = children;
    const { current } = instance;
    const script = document.createElement('script');
    script.innerHTML = src;
    const timeout = setTimeout(() => current && current.appendChild(script), 0);

    return () => {
      clearTimeout(timeout);
      if (current) current.removeChild(script);
    };
  }, [children]);

  return <div ref={instance} />;
};

ElementorScript.propTypes = {
  children: PropTypes.node.isRequired
};

export default ElementorScript;
