import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link as GatsbyLink } from 'gatsby';

import Link, { LinkExternal } from 'orm-react/components/Link';

import parser from '../../helper/parser';
import Layout, { LayoutMain } from '../../components/Layout';
import Image from '../../components/Image';
import SEO from '../../components/SEO';

import {
  ElementorCounter,
  ElementorContact,
  ElementorIcon,
  ElementorProgress,
  ElementorScript,
  ElementorTabs,
  ElementorToggle,
  ElementorToggleOpened
} from '../../components/Elementor';

import '../../icons';

const EXTERNAL_CHECK = /^(https?:\/\/|mailto:|tel:)/;

const removeImageSize = (src: string) => src.replace(/-\d+x\d+/g, '');

const getProcessor = ({ images, sources, srcUrl }) =>
  parser({
    link: (props: any) =>
      process.env.STAGING ? createElement('link', props) : null,
    iframe: (props: any) => createElement('iframe', props),
    script: (props: any) => createElement(ElementorScript, props),
    i: ({ className, ...props }: { className: string }) =>
      createElement(ElementorIcon, { className, ...props }),
    a: ({ href, ...props }: { href: string }) => {
      if (
        href &&
        href.indexOf(process.env.GATSBY_SITE_URL) !== 0 &&
        EXTERNAL_CHECK.test(href)
      ) {
        return createElement(LinkExternal, { href, ...props });
      } else if (!href) {
        return createElement(Link, { href, ...props });
      }

      switch (href) {
        default:
          return createElement(GatsbyLink, {
            // replace last slash in link and url sate
            to: String(href)
              .replace(/\/$/, '')
              .replace(process.env.GATSBY_SITE_URL, ''),
            ...props
          });
      }
    },
    img: (props: any) => {
      const src = removeImageSize(props.src);
      const image = Object.keys(images).find((key) => key.includes(src));

      return image && images[image]
        ? createElement(Image, {
            image: images[image].image,
            ...props
          })
        : null;
    },
    span: ({ className, ...props }: { className: string }) => {
      switch (className) {
        case 'elementor-toggle-icon-opened':
          return createElement(ElementorToggleOpened, { className });
        case 'elementor-counter-number':
          // @ts-ignore
          return createElement(ElementorCounter, { className, ...props });
        default:
          return createElement('span', { className, ...props });
      }
    },
    div: ({ className, ...props }: { className: string }) => {
      switch (className) {
        case 'elementor-toggle':
        case 'elementor-accordion':
          // @ts-ignore
          return createElement(ElementorToggle, { className, ...props });
        case 'elementor-progress-bar':
          // @ts-ignore
          return createElement(ElementorProgress, { className, ...props });
        case 'elementor-tabs':
          // @ts-ignore
          return createElement(ElementorTabs, { className, ...props });
        case 'elementor-contact':
          return createElement(ElementorContact, { className, ...props });
        default:
          return createElement('div', { className, ...props });
      }
    },
    // @ts-ignore
    section: ({ className, 'data-settings': dataSettings, ...props }) =>
      createElement('section', {
        className,
        'data-settings': dataSettings,
        ...props
      })
  });

const StandardPage = ({ data }: { data: any }) => {
  const {
    wpContent: {
      page: {
        title,
        content,
        elementorImages: images,
        elementorSources: sources,
        featuredImage: media,
        tags,
        acfSeo,
        acfSlider: { slider }
      },
      generalSettings: { url: srcUrl }
    }
  } = data;
  const imgMapping = (
    (images || []) as { url: string; link: string; image: string }[]
  ).reduce(
    (list, { url, link, image, ...rest }) => ({
      ...list,
      [url]: { link, image, ...rest }
    }),
    {} as Record<string, { link: string; image: string }>
  );

  const srcMapping = (
    (sources || []) as { url: string; alt: string; source: string }[]
  ).reduce(
    (list, { url, alt, source }) => ({
      ...list,
      [url]: {
        url,
        alt,
        source
      }
    }),
    {}
  );

  const body =
    content &&
    getProcessor({
      images: imgMapping,
      sources: srcMapping,
      srcUrl
    }).processSync(
      String(content)
        .replace(new RegExp(srcUrl, 'g'), '')
        .replace(/<!--[^\\[].+-->/g, '')
        .replace(/[\r\n\t]+/g, ' ')
        .replace(/>[\s]+</g, '><')
        .replace(/[\s]+/g, ' ')
        .replace(/"({.*?[^{]})"/g, (a, b) => `"${b.replace(/"/g, '@')}"`)
    ).result;

  return (
    <Layout>
      <SEO
        title={(acfSeo && acfSeo.title) || title}
        description={acfSeo && acfSeo.description}
        keywords={
          (tags.edges &&
            tags.edges.map(({ name }: { name: string }) => name)) ||
          []
        }
      />
      <LayoutMain>{body}</LayoutMain>
    </Layout>
  );
};

StandardPage.propTypes = {
  data: PropTypes.shape({}).isRequired
};

export const pageQuery = graphql`
  query pageQuery($id: ID!) {
    wpContent {
      generalSettings {
        url
      }
      page(id: $id) {
        id
        content
        elementorImages {
          url
          link
          alt
          title
          image {
            childImageSharp {
              id
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, JPG])
            }
          }
        }
        elementorSources {
          url
          alt
          source {
            absolutePath
            id
          }
        }
        elementorData
        slug
        status
        link
        title
        menuOrder
        tags {
          edges {
            node {
              name
              slug
              id
              link
            }
          }
        }
        acfSeo {
          title
          description
        }
        acfSlider {
          slider {
            id
            title
            altText
            description
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1920, maxHeight: 1080) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        featuredImage {
          node {
            id
            slug
            title
            caption
            altText
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1920, maxHeight: 1080) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default StandardPage;
