type KeyTrans = { [index: string]: string };

export const SALUTATION: KeyTrans = {
  m: __('label.salutation.m'),
  w: __('label.salutation.w'),
  d: __('label.salutation.d'),
  o: __('label.salutation.o')
};

export const USER_ROLE: KeyTrans = {
  ADMIN: __('label.user.role.admin'),
  ACCOUNT: __('label.user.role.account'),
  CLIENT: __('label.user.role.client')
};

export const USER_SIMPLE_ROLE: KeyTrans = {
  ACCOUNT: __('label.user.role.account'),
  CLIENT: __('label.user.role.client')
};

export const USER_ACCESS_ROLE: KeyTrans = {
  ACCOUNT: __('label.access.role.account'),
  GROUP: __('label.access.role.group'),
  CLIENT: __('label.access.role.client')
};

export const EXPORT_FILE_TYPE: KeyTrans = {
  CSV: __('label.file.type.csv'),
  PDF: __('label.file.type.pdf')
};

export const EXPORT_ORDER_TYPE: KeyTrans = {
  DETAILED: __('label.export.order.type.detailed'),
  ACCUMULATED: __('label.export.order.type.accumulated'),
  SPECIAL: __('label.export.order.type.special'),
  INDIVIDUAL_CSV: __('label.export.order.type.individual.csv')
};

export const ACCUMULATED_GROUP = {
  product: __('label.group.product'),
  clientDelivery: __('label.group.delivery.product'),
  deliveryTime: __('label.group.delivery.time.product')
};

export const EXPORT_IMPORT_TYPE: KeyTrans = {
  CUSTOMER: __('label.customers'),
  PRODUCT: __('label.products'),
  PRODUCT_GROUP: __('label.product.groups'),
  SALES: __('label.sales')
};

export const EXPORT_IMPORT_STATUS: KeyTrans = {
  IMPORTED: __('label.status.imported'),
  UPLOADED: __('label.status.uploaded'),
  PENDING: __('label.status.pending'),
  ERROR: __('label.status.error')
};

export const EXPORT_IMPORT_FILE: KeyTrans = {
  CSV: __('label.file.type.csv')
};

export const ENCODING: KeyTrans = {
  utf8: __('label.encoding.utf8'),
  latin1: __('label.encoding.latin1'),
  usascii: __('label.encoding.usascii'),
  msansi: __('label.encoding.msansi')
};

export const ORDERING_DAYS: KeyTrans = {
  '1a': __('label.monday'),
  '2b': __('label.tuesday'),
  '3c': __('label.wednesday'),
  '4d': __('label.thursday'),
  '5e': __('label.friday'),
  '6f': __('label.saturday'),
  '0g': __('label.sunday')
};

export const WEEKDAYS: KeyTrans = {
  monday: __('label.monday'),
  tuesday: __('label.tuesday'),
  wednesday: __('label.wednesday'),
  thursday: __('label.thursday'),
  friday: __('label.friday'),
  saturday: __('label.saturday'),
  sunday: __('label.sunday')
};

export const ADDITIVE: KeyTrans = {
  additive1: __('label.additive.1'),
  additive2: __('label.additive.2'),
  additive3: __('label.additive.3'),
  additive4: __('label.additive.4'),
  additive5: __('label.additive.5'),
  additive6: __('label.additive.6'),
  additive7: __('label.additive.7'),
  additive8: __('label.additive.8'),
  additive9: __('label.additive.9'),
  additive10: __('label.additive.10'),
  additive11: __('label.additive.11'),
  additive12: __('label.additive.12'),
  additive13: __('label.additive.13'),
  additive14: __('label.additive.14')
};

export const ALLERGEN: KeyTrans = {
  allergen1: __('label.allergen.1'),
  allergen2: __('label.allergen.2'),
  allergen3: __('label.allergen.3'),
  allergen4: __('label.allergen.4'),
  allergen5: __('label.allergen.5'),
  allergen6: __('label.allergen.6'),
  allergen7: __('label.allergen.7'),
  allergen8: __('label.allergen.8'),
  allergen9: __('label.allergen.9'),
  allergen10: __('label.allergen.10'),
  allergen11: __('label.allergen.11'),
  allergen12: __('label.allergen.12'),
  allergen13: __('label.allergen.13'),
  allergen14: __('label.allergen.14')
};

export const ALLERGEN_INFO: KeyTrans = {
  allergen1: __('label.allergen.info.1'),
  allergen2: __('label.allergen.info.2'),
  allergen3: __('label.allergen.info.3'),
  allergen4: __('label.allergen.info.4'),
  allergen5: __('label.allergen.info.5'),
  allergen6: __('label.allergen.info.6'),
  allergen7: __('label.allergen.info.7'),
  allergen8: __('label.allergen.info.8'),
  allergen9: __('label.allergen.info.9'),
  allergen10: __('label.allergen.info.10'),
  allergen11: __('label.allergen.info.11'),
  allergen12: __('label.allergen.info.12'),
  allergen13: __('label.allergen.info.13'),
  allergen14: __('label.allergen.info.14')
};

export const ARTICLE_FEATURE: KeyTrans = {
  VEGAN: __('label.feature.vegan'),
  VEGETARIAN: __('label.feature.vegetarian'),
  BIO: __('label.feature.bio'),
  KOSHER: __('label.feature.kosher'),
  ALCOHOLIC: __('label.feature.alcoholic'),
  SPIRITUOUS: __('label.feature.spirituous'),
  NONALCOHOLIC: __('label.feature.nonalcoholic'),
  SPICY: __('label.feature.spicy'),
  VERY_SPICY: __('label.feature.very.spicy'),
  LACTOSE_FREE: __('label.feature.lactose.free'),
  GLUTEN_FREE: __('label.feature.gluten.free'),
  GARLIC: __('label.feature.garlic'),
  LAMB: __('label.feature.lamb'),
  PORK: __('label.feature.pork'),
  BEEF: __('label.feature.beef')
};

export const ARTICLE_TYPE: KeyTrans = {
  FOOD: __('label.product.type.food'),
  DRINK: __('label.product.type.drink')
};

export const USER_ACL_RESOURCE: KeyTrans = {
  product: __('label.acl.resource.product'),
  schedule: __('label.acl.resource.schedule'),
  order: __('label.acl.resource.order'),
  customer: __('label.acl.resource.customer'),
  sales: __('label.acl.resource.sales')
};

export const ORDER_EXPORT_FIELDS: KeyTrans = {
  scheduleDate: __('label.export.field.scheduleDate'),
  productNumber: __('label.export.field.productNumber'),
  groupProductNumber: __('label.export.field.groupProductNumber'),
  groupName: __('label.export.field.groupName'),
  productName: __('label.export.field.productName'),
  customerReference: __('label.export.field.customerReference'),
  customerName: __('label.export.field.customerName'),
  customerGivenName: __('label.export.field.customerGivenName'),
  customerFamilyName: __('label.export.field.customerFamilyName'),
  deliveryName: __('label.export.field.deliveryName'),
  deliveryTime: __('label.export.field.deliveryTime'),
  amount: __('label.export.field.amount'),
  price: __('label.export.field.price'),
  priceTotal: __('label.export.field.priceTotal')
};

export const PRODUCT_EXPORT_FIELDS = {
  id: __('label.export.field.id'),
  productGroup: __('label.export.field.product.group.id'),
  productNumber: __('label.export.field.productNumber'),
  name: __('label.export.field.productName'),
  description: __('label.export.field.description'),
  price: __('label.export.field.price'),
  type: __('label.export.field.type'),
  locked: __('label.export.field.locked'),
  additive: __('label.export.field.additive'),
  feature: __('label.export.field.feature'),
  allergen: __('label.export.field.allergen'),
  minPerPerson: __('label.export.field.minPerPerson'),
  maxPerPerson: __('label.export.field.maxPerPerson'),
  warningPerPerson: __('label.export.field.warningPerPerson')
};
