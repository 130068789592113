import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';

import classNames from 'orm-react/helper/class-names';

import styles from './styles.module.scss';

const FormButton: FC<{
  children: ReactNode;
  dir?: 'left' | 'center' | 'right';
}> = ({ children, dir = 'center' }) => (
  <div className={classNames(styles.form__button, dir)}>{children}</div>
);

FormButton.propTypes /* remove-proptypes */ = {
  dir: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.node.isRequired
};

export default FormButton;
