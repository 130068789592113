import { FC, ReactNode } from 'react';

import classNames from 'orm-react/helper/class-names';

import styles from './styles.module.scss';

const Alert: FC<{
  type: 'info' | 'error' | 'warning' | 'success';
  wide?: 'sm' | 'md' | 'full';
  className?: string;
  children?: ReactNode;
}> = ({ type, className, children, wide = 'full' }) => (
  <div className={classNames(styles.alert, wide, type, className)} role="alert">
    {children}
  </div>
);

export default Alert;
