import React, { FC } from 'react';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';

const Image: FC<{
  src: string;
  image: IGatsbyImageData;
  alt?: string;
  className?: string;
  width?: number;
  height?: number;
}> = ({ image, alt = '', className }) => {
  const sharp = getImage(image);

  return sharp ? (
    <GatsbyImage image={sharp} alt={alt} imgClassName={className} />
  ) : null;
};

Image.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  // @ts-ignore
  image: PropTypes.shape({}).isRequired
};

export default Image;
