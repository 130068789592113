import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const ElementorIcon: FC<{
  className: string;
}> = ({ className, ...props }) => {
  const split = className.replace('fa-', '').split(' ');

  return (
    <i {...props}>
      {split.length > 1 ? <FontAwesomeIcon icon={split as IconProp} /> : null}
    </i>
  );
};

export default ElementorIcon;
