import { createElement } from 'react';
import { unified } from 'unified';
import parse from 'rehype-parse';
import rehype2react from 'rehype-react';

export default function parser(components: any) {
  return unified()
    .use(parse, {
      fragment: true
    })
    .use(rehype2react, {
      createElement,
      components
    });
}
