import { useState } from 'react';
import { Link } from 'gatsby';
import { Form } from 'react-final-form';
import { useMutation } from '@apollo/react-hooks';

import contactGql from 'orm-react/graphql/mutations/contact.gql';

import Conforma from 'orm-react/helper/conforma';
import useRecaptcha from 'orm-react/hooks/recaptcha';

import {
  FieldButton,
  FieldText,
  FieldInput,
  FieldSelect,
  FieldGroup
} from 'orm-react/components/Field';
import {
  selectOptions,
  FieldSelectOptions
} from 'orm-react/components/Field/Select';
import { FormRequired } from 'orm-react/components/Form';
import { Button } from 'orm-react/components/Button';
import { LinkWrapper } from 'orm-react/components/Link';
import { SALUTATION } from 'orm-react/i18n';

import BlockSuccess from '../../Block/Success';

const subjectText = {
  question: __('label.subject.question'),
  feedback: __('label.subject.feedback'),
  recommendation: __('label.subject.recommendation'),
  complaint: __('label.subject.complaint'),
  other: __('label.subject.other')
};

const FormContact = () => {
  const [submitted, setSubmitted] = useState(false);
  const [contact] = useMutation(contactGql);
  const { reload } = useRecaptcha('contact');

  const onSubmit = ({
    email,
    subject,
    remark,
    salutation,
    givenName,
    familyName,
    mobileNumber,
    company
  }: {
    email: string;
    subject: string;
    remark: string;
    salutation: string;
    givenName: string;
    familyName: string;
    mobileNumber: string;
    company: string;
  }) =>
    reload()
      .then((token) =>
        contact({
          variables: {
            token,
            email,
            subject,
            remark,
            salutation,
            givenName,
            familyName,
            mobileNumber,
            company,
            source: 'partner'
          }
        })
      )
      .then(() => {
        setSubmitted(true);
      })
      .catch(() => {
        setSubmitted(true);
      });

  const validate = (values: any) =>
    Conforma(values)
      .valid({
        email: 'email',
        givenName: 'required',
        salutation: 'required',
        familyName: 'required',
        remark: 'required'
      })
      .exec();

  if (submitted) {
    return (
      <BlockSuccess title="Vielen Dank für Ihre Kontaktaufnahme">
        Wir werden uns in Kürze mit Ihnen in Verbindung setzen.
      </BlockSuccess>
    );
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      validateOnBlur
      render={({ submitting, handleSubmit }) => (
        <form
          autoComplete="nope"
          noValidate
          onSubmit={handleSubmit}
          className="max-w-xl mx-auto"
        >
          <FieldGroup>
            <FieldSelect name="subject" label="Betreff">
              <FieldSelectOptions options={selectOptions(subjectText)} />
            </FieldSelect>
          </FieldGroup>
          <FieldGroup>
            <FieldText
              name="remark"
              label={__('label.your.request')}
              elastic
              required
            />
          </FieldGroup>
          <FieldGroup>
            <FieldInput name="company" label={__('label.company')} />
          </FieldGroup>
          <FieldGroup>
            <FieldSelect
              name="salutation"
              label={__('label.salutation')}
              required
            >
              <FieldSelectOptions options={selectOptions(SALUTATION)} />
            </FieldSelect>
            <FieldInput
              name="givenName"
              label={__('label.given.name')}
              required
            />
            <FieldInput
              name="familyName"
              label={__('label.family.name')}
              required
            />
          </FieldGroup>
          <FieldGroup>
            <FieldInput
              name="email"
              label={__('label.email')}
              type="email"
              required
            />
            <FieldInput
              name="mobileNumber"
              label={__('label.phone')}
              type="tel"
            />
          </FieldGroup>
          <FormRequired />
          <LinkWrapper>
            <div className="max-w-md mx-auto text-center m-4">
              {'Mit dem Absenden des Formulars, stimme ich den '}
              <Link to="/datenschutz" target="_blank">
                {__('label.data.protection.rules')}
              </Link>
              {' zu.'}
            </div>
          </LinkWrapper>
          <FieldButton className="justify-center">
            <Button state="primary" type="submit" disabled={submitting}>
              {__('btn.submit')}
            </Button>
          </FieldButton>
        </form>
      )}
    />
  );
};

export default FormContact;
