import React from 'react';

import FormContact from '../../Form/Contact';

const ElementorContact = (props: any) => (
  <div {...props}>
    <FormContact />
  </div>
);

export default ElementorContact;
