import Alert, {
  AlertBody,
  AlertIcon,
  AlertMessage
} from 'orm-react/components/Alert';

const AlertEmptyList = () => (
  <Alert wide="sm" type="info">
    <AlertBody>
      <AlertIcon icon={['fas', 'triangle-exclamation']} />
      <AlertMessage title={__('alert.info.result.is.empty')} />
    </AlertBody>
  </Alert>
);

export default AlertEmptyList;
