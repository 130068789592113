import { ReactNode, useState } from 'react';
import PropTypes from 'prop-types';

import useTransitionEnd from 'orm-react/hooks/transition-end';
import classNames from 'orm-react/helper/class-names';

import styles from './styles.module.scss';

const FormFieldset = ({
  children,
  openable,
  grouped,
  label
}: {
  children: ReactNode;
  openable?: boolean;
  grouped?: boolean;
  label: ReactNode;
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const openField = () => setOpen(true);

  const onClose = () => {
    setOpen(false);
  };

  const { close, showed, node } = useTransitionEnd<HTMLLegendElement>({
    show: open,
    onClose
  });

  const LegendContent = openable ? (
    <button type="button" onClick={open ? close : openField}>
      {label}
    </button>
  ) : (
    label
  );

  return (
    <fieldset
      className={classNames(
        styles.form__fieldset,
        openable && 'openable',
        grouped && 'grouped'
      )}
    >
      <legend
        ref={node}
        className={classNames(showed && 'active')}
        aria-hidden="true"
      >
        {LegendContent}
      </legend>
      {openable ? (open ? children : null) : children}
    </fieldset>
  );
};

FormFieldset.propTypes /* remove-proptypes */ = {
  children: PropTypes.node.isRequired,
  openable: PropTypes.bool,
  label: PropTypes.node
};

export default FormFieldset;
