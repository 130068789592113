import { FC, ReactNode } from 'react';

import './styles.scss';

const AlertMessage: FC<{
  title: string;
  children?: ReactNode;
}> = ({ title, children }) => (
  <div className="alert__message">
    <div>{title}</div>
    {children}
  </div>
);

export default AlertMessage;
