import React, { FC, ReactNode, useEffect, useRef } from 'react';

import './styles.scss';

const ElementorTabs: FC<{
  children: ReactNode;
}> = ({ children, ...props }) => {
  const tabs = useRef<HTMLDivElement>(null);

  let listTab: HTMLElement[] = [];
  let listContent: HTMLElement[] = [];
  const listener: EventListener[] = [];

  const tabsMenu = (index: number) => () => {
    listTab.forEach((node, i) => {
      if (index === i && !node.classList.contains('elementor-active')) {
        node.classList.add('elementor-active');
        listContent[i].classList.add('elementor-active');
      } else if (node.classList.contains('elementor-active')) {
        node.classList.remove('elementor-active');
        listContent[i].classList.remove('elementor-active');
      }
    });
  };

  useEffect(() => {
    const { current } = tabs;

    if (!current) return;

    listTab = Array.from(current.querySelectorAll('.elementor-tab-title'));
    listContent = Array.from(
      current.querySelectorAll('.elementor-tab-content')
    );

    current.querySelectorAll('a').forEach((node) => {
      node.addEventListener('click', (e) => {
        e.preventDefault();
        return false;
      });
    });

    listTab.forEach((node, i) => {
      listener[i] = tabsMenu(i);
      node.addEventListener('click', listener[i]);
    });

    if (listener.length) tabsMenu(0)();

    return () => {
      listTab.forEach((node, i) =>
        node.removeEventListener('click', listener[i])
      );
    };
  }, []);

  return (
    <div ref={tabs} {...props}>
      {children}
    </div>
  );
};

export default ElementorTabs;
