import { library } from '@fortawesome/fontawesome-svg-core';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkedAlt';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faHandshake } from '@fortawesome/free-solid-svg-icons/faHandshake';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons/faCaretUp';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons/faCaretRight';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons/faDotCircle';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faPeopleArrows } from '@fortawesome/free-solid-svg-icons/faPeopleArrows';
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons/faExpandArrowsAlt';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt';
import { faStore } from '@fortawesome/free-solid-svg-icons/faStore';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons/faShoppingCart';
import { faRocket } from '@fortawesome/free-solid-svg-icons/faRocket';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons/faShieldAlt';
import { faClock } from '@fortawesome/free-regular-svg-icons/faClock';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons/faCalendarCheck';
import { faPaintbrush } from '@fortawesome/free-solid-svg-icons/faPaintbrush';
import { faAt } from '@fortawesome/free-solid-svg-icons/faAt';

import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons/faFacebookMessenger';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import { faTelegram } from '@fortawesome/free-brands-svg-icons/faTelegram';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';

library.add(
  faAt,
  faPaintbrush,
  faCalendarCheck,
  faClock,
  faShieldAlt,
  faUsers,
  faGlobe,
  faMapMarkedAlt,
  faPhone,
  faEnvelope,
  faFacebook,
  faFacebookMessenger,
  faWhatsapp,
  faTelegram,
  faInstagram,
  faHandshake,
  faTimes,
  faChevronDown,
  faCheckCircle,
  faTimesCircle,
  faPlus,
  faMinus,
  faStar,
  faTwitter,
  faYoutube,
  faCaretRight,
  faCaretUp,
  faDotCircle,
  faCheck,
  faInfoCircle,
  faPeopleArrows,
  faExpandArrowsAlt,
  faCalendarAlt,
  faStore,
  faShoppingCart,
  faRocket,
  faEye
);
