import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ElementorToggleOpened: FC<{
  className: string;
}> = ({ className }) => (
  <span className={className}>
    <i>
      <FontAwesomeIcon
        icon={[
          'fas',
          className === 'elementor-accordion-icon-opened' ? 'minus' : 'caret-up'
        ]}
      />
    </i>
  </span>
);

export default ElementorToggleOpened;
