import { useState, useEffect } from 'react';
import { ReCaptchaInstance, load, getInstance } from 'recaptcha-v3';

let STARTED = false;
let INIT = false;

const init = () =>
  load(GOOGLE_RECAPTCHA)
    .then((recaptcha) => {
      INIT = true;

      return recaptcha;
    })
    .catch((error) => {
      INIT = false;
      throw error;
    });

/** @tailwind grecaptcha-badge */
export default function useRecaptcha(actionName: string, autoload = true) {
  const [token, setToken] = useState<string | null>(null);

  const getToken = (recaptcha: ReCaptchaInstance) =>
    recaptcha
      .execute(actionName)
      .then((ntoken) => {
        setToken(ntoken);
        return ntoken;
      })
      .catch((error) => {
        setToken(null);

        throw Error(error);
      });

  const reload = () =>
    GOOGLE_RECAPTCHA
      ? (INIT ? Promise.resolve(getInstance()) : init()).then(getToken)
      : Promise.resolve(actionName);

  useEffect(() => {
    if (STARTED) return;

    STARTED = true;

    autoload && !INIT && GOOGLE_RECAPTCHA && init();
  }, []);

  return { token, reload };
}
