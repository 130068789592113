import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormSpy } from 'react-final-form';

const AutoSave = ({ name, values }: { name: string; values: object }) => {
  useEffect(() => {
    sessionStorage.setItem(`formAutoSave_${name}`, JSON.stringify(values));
  }, [values]);

  return null;
};

AutoSave.propTypes /* remove-proptypes */ = {
  name: PropTypes.string.isRequired,
  values: PropTypes.shape({}).isRequired
};

const FormAutoSave = ({ name }: { name: string }) => (
  <FormSpy subscription={{ active: true, values: true }}>
    {({ values }) => <AutoSave name={name} values={values} />}
  </FormSpy>
);

FormAutoSave.propTypes /* remove-proptypes */ = {
  name: PropTypes.string.isRequired
};

FormAutoSave.getValuesByName = <Type extends Record<string, any>>({
  name
}: {
  name: string;
}): Type => {
  const cache = sessionStorage.getItem(`formAutoSave_${name}`);

  return cache ? JSON.parse(cache) : {};
};

FormAutoSave.remValuesByName = ({ name }: { name: string }): void => {
  sessionStorage.removeItem(`formAutoSave_${name}`);
};

export default FormAutoSave;
