import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import useIntersectionObserver from 'orm-react/hooks/intersection-observer';

const ElementorProgress: FC<{
  className: string;
  children: ReactNode;
  'data-max': string;
}> = ({ className, 'data-max': max, children }) => {
  const [width, setWidth] = useState<string>('0');
  const ref = useRef<HTMLDivElement>(null);
  const intersection = useIntersectionObserver(ref, {});
  const isVisible = !!intersection?.isIntersecting;

  useEffect(() => {
    setWidth(isVisible ? max : '0');
  }, [isVisible]);

  return (
    <div ref={ref} className={className} style={{ width: `${width}%` }}>
      {children}
    </div>
  );
};

ElementorProgress.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  'data-max': PropTypes.string.isRequired
};

export default ElementorProgress;
